export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filter: false,
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
        filter: false,
    },
    {
        headerName: 'First Name',
        field: 'firstName',
        filter: false,
    },
    {
        headerName: 'Resides in Household',
        field: 'residesInHousehold',
        filter: false,
    },
    {
        headerName: 'Relationship',
        field: 'relationship',
        filter: false,
    },
    {
        headerName: 'Home Phone',
        field: 'homePhone',
        filter: false,
    },
    {
        headerName: 'Cell Phone',
        field: 'cellPhone',
        filter: false,
    },
    {
        headerName: 'Work Phone',
        field: 'workPhone',
        filter: false,
    },
    {
        headerName: 'Work Phone Ext.',
        field: 'workPhoneExtension',
        filter: false,
    },
    {
        headerName: 'email Address',
        field: 'emailAddress',
        filter: false,
    },
    {
        headerName: 'Written Language',
        field: 'writtenLanguage',
        filter: false,
    },
    {
        headerName: 'Oral Language',
        field: 'oralLanguage',
        filter: false,
    },
    {
        headerName: 'Authoriziation Code',
        field: 'authorizationCode',
        filter: false,
    },
    {
        headerName: 'Emergency Contact Indicator',
        field: 'emergencyContactIndicator',
        filter: false,
    },
    {
        headerName: 'Custodial Indicator',
        field: 'custodialIndicator',
        filter: false,
    },
    {
        headerName: 'Correspondence Indicator',
        field: 'correspondenceIndicator',
        filter: false,
    },
    {
        headerName: 'Pickup Indicator',
        field: 'pickupIndicator',
        filter: false,
    },
    {
        headerName: 'Restricted Access Indicator',
        field: 'restrictedAccessIndicator',
        filter: false,
    },
    {
        headerName: 'Priority Number',
        field: 'priorityNumber',
        filter: false,
    },
    {
        headerName: 'Contact ID',
        field: 'contactId',
        filter: false,
    },
    {
        headerName: 'Address',
        field: 'address',
        filter: false,
    },
    {
        headerName: 'City',
        field: 'city',
        filter: false,
    },
    {
        headerName: 'State',
        field: 'state',
        filter: false,
    },
    {
        headerName: 'ZIP',
        field: 'zip',
        filter: false,
    },
    {
        headerName: 'Comments',
        field: 'comments',
        filter: false,
    },
    {
        headerName: 'Employer',
        field: 'employer',
        filter: false,
    },
    {
        headerName: 'Job Title',
        field: 'jobTitle',
        filter: false,
    },
    {
        headerName: 'Active Military Indicator',
        field: 'activeMilitaryIndicator',
        filter: false,
    },
    {
        headerName: 'Active Civilian Indicator',
        field: 'activeCivilianIndicator',
        filter: false,
    },
    {
        headerName: 'Active Duty Indicator',
        field: 'activeDutyIndicator',
        filter: false,
    },
    {
        headerName: 'Branch of Service',
        field: 'branchOfService',
        filter: false,
    },
]
